import React, {useState} from 'react';
import './MobileAnswerForm.css';
import checkAnswer from './CheckAnswer';

function MobileAnswerForm({direction, onAnswerSubmitted}) {

    const [answer, setAnswer] = useState();

    function onSubmit(e) {
        e.preventDefault();
        const newText = (answer || '')
            .toUpperCase()
            .replace(/[^A-Z]/gi, ''); // allow only alphabet chars
        checkAnswer(newText, direction).then(correct => {
            onAnswerSubmitted({answer, correct});
        })
    }

    function onChange(e) {
        setAnswer(e.target.value);
    }

    return <div id="AnswerForm">
        <form onSubmit={onSubmit} autoComplete="off">
            <label>
                <input id="Answer" type="text" name="puzzle-answer" 
                    maxLength="9"
                    onChange={onChange}></input>
            </label><br/>
            <button className="submit-button" type="submit">Submit</button>
        </form>
    </div>;
};

export default MobileAnswerForm;

 /* eslint-disable */ // haha deal with it
import * as d3 from 'd3';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import './Clockface.css';
import { Moon, Sun } from './Eclipses';

// Allows for h4x0r-ing in the console
window.d3 = d3

export const clockRadius = 42,
    hourHandLength = 2 * clockRadius / 5 + 4,
    minuteHandLength = clockRadius - 14,
    secondHandLength = clockRadius - 16,
    secondHandBalance = 8;

export const hourScale = d3.scaleLinear()
    .range([0, 330])
    .domain([0, 11]);

const minuteScale = d3.scaleLinear()
    .range([0, 354])
    .domain([0, 59]);
const secondScale = minuteScale;

const debugScale = d3.scaleLinear()
    .range([0, 360])
    .domain([0, 11]);

// dark (0) to light (1)
const sunrise = d3.interpolate("#427e8c", "#68c2d8");

// Damn it feels good to be a gansta
window.GLOBAL_TIME = {
    offset: 0,
    timeTravelSpeed: 0,
}

function Hand(props) {
    return <line
        x1="0" x2="0"
        y1={props.balance ? props.balance : 0}
        y2={props.length}
    ></line>;
}

// Hand lengths are negative so that a rotation of 0deg points upwards, instead of downwards
function HourHand(props) {
    return <g key="hour" className="clock-hand" id="hour-hand" transform={"rotate(" + hourScale(props.time.hour) + ")"}>
        <Hand length={-hourHandLength} />
        <Sun centre={-hourHandLength} hour={hourScale(props.time.hour)} minute={minuteScale(props.time.minute)} />
    </g>;
}

function MinuteHand(props) {
    return <g key="minute" className="clock-hand" id="minute-hand" transform={"rotate(" + minuteScale(props.time.minute) + ")"}>
        <Hand length={-minuteHandLength} />
        <Moon centre={-minuteHandLength} hour={hourScale(props.time.hour)} minute={minuteScale(props.time.minute)} />
    </g>;
}

function SecondHand(props) {
    return <g key="second" className="clock-hand" id="second-hand" transform={"rotate(" + secondScale(props.time.second) + ")"}>
        <Hand length={-secondHandLength} balance={secondHandBalance} />
    </g>;
}

function Rays(props) {
    const scale = 500;
    return <g id="rays" style={{pointerEvents: 'none'}}>
        <image href="images/shadowray.png" x={-scale/2} y={-scale/2} height={scale + 'px'} width={scale + 'px'}
            transform={`rotate(${minuteScale(props.time.minute)})`}
        />
    </g>
};

function ClockHands({metaAnswerSolved}) {
    const [time, setTime] = useState({
        hour: 0,
        minute: 0,
        second: 0
    });

    function calculateTime() {
        var t = new Date();
        t.setSeconds(t.getSeconds() + window.GLOBAL_TIME.offset);
        return t;
    }

    function optionalTransition(selection, shouldTransitionSmoothly, duration) {
        if (!shouldTransitionSmoothly) {
            return selection;
        }
        return selection
            .transition()
            .duration(duration);
    }

    function advanceBackground(time, shouldTransitionSmoothly) {
        // Hack hack hack. Would be nice to commonise this a bit but yolo

        const minuteAngle = minuteScale(time.minute);
        const hourAngle = hourScale(time.hour);

        // 0 for dark, 1 for light
        const degreesEitherSideAtWhichWeStartToGetDarker = 2;
        const lightLevel = Math.min(
            Math.abs(hourAngle - minuteAngle) / degreesEitherSideAtWhichWeStartToGetDarker,
            1);

        // In case we ever want it to change by time of day again, here's how that works
        // var t = new Date();
        // t.setSeconds(t.getSeconds() + window.GLOBAL_TIME.offset);
        // const hrs = 1 - Math.abs(t.getHours() - 12) / 12;
        // const lightLevelBasedOnTimeOfDay = 1 - Math.abs(t.getHours() - 12) / 12;

        // cast a shadow only when we make things darker
        document.getElementById('rays').style.opacity = 1 - lightLevel

        d3.select("#clock-background")
            .call(optionalTransition, shouldTransitionSmoothly, 1000)
            .style("background-color", sunrise(lightLevel));
        // Ideally we wouldn't change the body colour too, but it's a bit of a faff to get
        // rid of the tiny errors between the size of the svg and the frame
        d3.select("body")
            .call(optionalTransition, shouldTransitionSmoothly, 1000)
            .style("background-color", sunrise(lightLevel));
    }

    function drawDebug() {
        return _.range(0, 11).map(i => {
            return <line key={`debughand-${i}`} className={"debug-hand"}
                x1="0" x2="0"
                y1={0}
                y2={-200}
                transform={"rotate(" + debugScale(i) + ")"}
                stroke="gray"
            ></line>;
        });
    }

    function tick(shouldTransitionSmoothly) {
        const t = calculateTime();
        const newTime = {
            hour: (t.getHours() % 12) + (t.getMinutes() / 60) + (t.getSeconds() / 3600),
            minute: t.getMinutes() + (t.getSeconds() / 60),
            second: t.getSeconds()
        };
        setTime(() => newTime);
        if (!metaAnswerSolved) {
            advanceBackground(newTime, shouldTransitionSmoothly);
        }
    }

    useEffect(() => {

        if (metaAnswerSolved) {
            window.GLOBAL_TIME.timeTravelSpeed = 0.5
            const solvedInterval = setInterval(() => {
                // Accelerate
                window.GLOBAL_TIME.timeTravelSpeed += Math.sign(window.GLOBAL_TIME.timeTravelSpeed) * 1
                window.GLOBAL_TIME.offset += window.GLOBAL_TIME.timeTravelSpeed;
                tick(false);
            }, 100);

            console.log("ending part 1")
            d3.select("#clock-background")
                .transition()
                .duration(5000)
                .style("background-color", "#427e8c");
            d3.select("body")
                .transition()
                .duration(5000)
                .style("background-color", "#427e8c");

            d3.select("#mice")
                .style("animation", "1s ease-in-out infinite normal none running MoveUpDownMouse");

           d3.selectAll(".frame-text-not-selected")
                .style("filter", "blur(0px)")
                .transition()
                .duration(10000)
                .style("filter", "blur(5px)");

            const solvedTimeout = setTimeout(() => {
                console.log("ending part 2")

                d3.select("#clock-background")
                    .transition()
                    .duration(5000)
                    .style("background-color", "#000000");
                d3.select("body")
                    .transition()
                    .duration(5000)
                    .style("background-color", "#000000");

                d3.select("#fish")
                    .style("filter", "drop-shadow(0px 0px 0px yellow)")
                    .transition()
                    .duration(10000)
                    .style("filter", "drop-shadow(0px 0px 8px yellow)");
                d3.selectAll(".frame")
                    .style("filter", "opacity(1)")
                    .transition()
                    .duration(10000)
                    .style("filter", "opacity(0)");
                d3.selectAll('.clock-centre')
                    .transition()
                    .duration(10000)
                    .style("fill", "rgb(20, 20, 20)");
            }, 5000)

            return () => { clearInterval(solvedInterval); clearTimeout(solvedTimeout) };
        }

        tick(false);

        const tickInterval = setInterval(() => {
            if (window.GLOBAL_TIME.timeTravelSpeed) {
                // If time travelling, don't attempt normal ticks
                return;
            }
            tick(true);
        }, 100);

        const travelInterval = setInterval(() => {
            if (!window.GLOBAL_TIME.timeTravelSpeed) {
                // If *not* time travelling, don't time travel *duh*
                return;
            }
            
            // Accelerate
            window.GLOBAL_TIME.timeTravelSpeed += Math.sign(window.GLOBAL_TIME.timeTravelSpeed) * 0.1
            window.GLOBAL_TIME.offset += window.GLOBAL_TIME.timeTravelSpeed;
            tick(false)
        }, 10);

        return () => { clearInterval(tickInterval); clearInterval(travelInterval) };
    }, [metaAnswerSolved]);

    return <g id="clock-hands">
        <defs>
            <image id="bitmap_ray_shadow" width="0.001" height="0.001" href="images/raydark.png" />
        </defs>
        <MinuteHand time={time} />
        <HourHand time={time} />
        <SecondHand time={time} />
        <Rays time={time}/>
    {/*drawDebug()*/}
    </g>;
}

export default ClockHands;

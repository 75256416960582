// Nicked from the lovely Erik Dahlström at https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3

function Dropshadow() {
    return <filter id="dropshadow" height="130%" width="140%">
        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
        <feOffset dx="1" dy="-3" result="offsetblur" />
        <feComponentTransfer>
            <feFuncA type="linear" slope="0.5" />
        </feComponentTransfer>
        <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
        </feMerge>
    </filter>
}

export default Dropshadow;

import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

function Popup({ isShowing, onClose, children }) {

  function closeOnOutsideClick(e) {
    if (!document.getElementById('PopupChildren').contains(e.target)) {
      onClose();
    }
  }

  useEffect(() => {
    function closeOnEscape(e) {
      if (e.key === 'Escape') {
        onClose();
      }
    }
    window.addEventListener('keydown', closeOnEscape);
    return () => {
      window.removeEventListener('keydown', closeOnEscape)
    }
  }, [onClose]);

  if (!isShowing) {
    return <React.Fragment />
  }

  return (
    <div onClick={closeOnOutsideClick}
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0,0,0,.7)',
        boxSizing: 'border-box',
        zIndex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
      <div id="PopupChildren"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}>
        {children}
      </div>
      <h1 style={{
        position: 'fixed', left: '0.5em', bottom: '0.5em', margin: 0, color: 'white'
      }}
        onClick={onClose}>
        <FontAwesomeIcon icon={faArrowLeft} style={{
          cursor: 'pointer'
        }} />
      </h1>
    </div>
  )
}

export default Popup;

/* eslint-disable */ // haha deal with it
import React, { useState, useEffect } from 'react';
import './App.css';
import Clock from './Clock';
import Popup from './Popup';
import Help from './Help';
import MobileAnswerForm from './MobileAnswerForm';
import checkAnswer from './CheckAnswer';
import { load, save } from './SaveGame';

const savedAnswers = load();

function App() {

  const [chosenSection, setSection] = useState();

  const [northAnswer, setNorthAnswer] = useState(savedAnswers.northAnswer);
  const [southAnswer, setSouthAnswer] = useState(savedAnswers.southAnswer);
  const [eastAnswer, setEastAnswer] = useState(savedAnswers.eastAnswer);
  const [westAnswer, setWestAnswer] = useState(savedAnswers.westAnswer);
  // We don't currently bother to save / load the meta answer, sorrryyyyyyyyy
  const [metaAnswer, setMetaAnswer] = useState({cipher: {}, answerText: '', answerCipherText: ''});
  const [metaAnswerSolved, setMetaAnswerSolved] = useState(false);

  useEffect(() => {
      checkAnswer(metaAnswer.answerCipherText, 'finale').then((correct) => {
          if (correct && !metaAnswerSolved) {
              setMetaAnswerSolved(true);

              // Animations in Clockhands.js take 15s to finish
              setTimeout(() => {
                window.location.href = metaAnswer.answerCipherText + ".html";
              }, 18000);
          }
      })
  }, [metaAnswer.answerCipherText]);

  function onAnswerSubmitted(answerObject) {
    switch (chosenSection) {
      case 'NORTH':
        setNorthAnswer(answerObject);
        setSection(null);
        break;
      case 'EAST':
        setEastAnswer(answerObject);
        setSection(null);
        break;
      case 'SOUTH':
        setSouthAnswer(answerObject);
        setSection(null);
        break;
      case 'WEST':
        setWestAnswer(answerObject);
        setSection(null);
        break;
      default:
        setSection(null);
        break;
    }
  }

  const allCorrect = [northAnswer, southAnswer, eastAnswer, westAnswer].every(a => a.correct);
  const answers = { northAnswer, southAnswer, eastAnswer, westAnswer, allCorrect };
  save(answers);

  useEffect(showWickedSickBannerToHackers, []);

  const onClose = () => setSection(null)

  const imgstyle = { maxHeight: '70vh', maxWidth: '100vw' }

  const popUpContents = () => {
    switch (chosenSection) {
      case "NORTH":
        return (
          <React.Fragment>
            <a href="images/north.jpg" target="_blank" rel="noreferrer">
              <img src="images/north_lowres.jpg" style={imgstyle} />
            </a>
          </React.Fragment>
        )
      case "EAST":
        return (
          <React.Fragment>
            <a href="images/east.jpg" target="_blank" rel="noreferrer">
              <img src="images/east_lowres.jpg" style={imgstyle} />
            </a>
          </React.Fragment>
        )
      case "SOUTH":
        return (
          <React.Fragment>
            <a href="images/south.jpg" target="_blank" rel="noreferrer">
              <img src="images/south.jpg" style={imgstyle} />
            </a>
          </React.Fragment>
        )
      case "WEST":
        return (
          <React.Fragment>
            <a href="images/west.jpg" target="_blank" rel="noreferrer">
              <img src="images/west_lowres.jpg" style={imgstyle} />
            </a>
          </React.Fragment>
        )
      default:
        return null;
    }
  }

  return (
    <div className="App">
      <div id="appContainer">
        <Clock viewSection={setSection} answers={answers} updateMetaAnswer={setMetaAnswer} metaAnswer={metaAnswer} metaAnswerSolved={metaAnswerSolved} />
        <Popup isShowing={!!chosenSection} onSubmit={onAnswerSubmitted} onClose={onClose}>
          {popUpContents()}
          <MobileAnswerForm
            style={{ maxHeight: '20vh' }}
            direction={chosenSection}
            onAnswerSubmitted={onAnswerSubmitted}
          />
        </Popup>
        <div id="fade-to-white"/>
      </div>
      <footer>
        {!chosenSection && <Help />}
      </footer>
    </div>
  );
}

export default App;

function showWickedSickBannerToHackers() {
  console.log(`
-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-

 __      __       .__                                                                .__               ._.
/  \\    /  \\ ____ |  |   ____  ____   _____   ____       ______  __ _________________|  |   ___________| |
\\   \\/\\/   // __ \\|  | _/ ___\\/  _ \\ /     \\_/ __ \\      \\____ \\|  |  \\___   /\\___   /  | _/ __ \\_  __ \\ |
 \\        /\\  ___/|  |_\\  \\__(  <_> )  Y Y  \\  ___/      |  |_> >  |  //    /  /    /|  |_\\  ___/|  | \\/\\|
  \\__/\\  /  \\___  >____/\\___  >____/|__|_|  /\\___  > /\\  |   __/|____//_____ \\/_____ \\____/\\___  >__|   __
       \\/       \\/          \\/            \\/     \\/  )/  |__|               \\/      \\/         \\/       \\/

You of course are free to use the console and your 1337 skillz to decipher the puzzle, but it may be more difficult than
solving the puzzle itself.
                                                          o           o
You have been warned!                    o               o           o                    o
                                        o            ><_>        ><_>                    o
                                    ><_>                                             ><_>    - The Wishing Fish Ensemble

-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-
`);
}

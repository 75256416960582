function save(value) {
    localStorage.setItem('wishing-fish-clock-answers', JSON.stringify(value));
}

const blankAnswer = () => ({answer: '', correct: false});

function load() {
    const value = localStorage.getItem('wishing-fish-clock-answers');
    const json = JSON.parse(value);
    return json || {
        northAnswer: blankAnswer(),
        southAnswer: blankAnswer(),
        eastAnswer: blankAnswer(),
        westAnswer: blankAnswer(),
        allCorrect: false,
    };
}

export { save, load };

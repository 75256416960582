/**
To create these works of art:
1) Open up xara/Wishing Fish Clock wonky letters.xar in your favourite vector graphics program that rhymes with "nara"
2) Mosey the letters around
3) Export as SVG, Inkscape mode (not sure if this matters), 96dpi (default)
4) Open that SVG in your second-favourite browser (mine's Edge)
5) Right-click, inspect element
6) Find the group that contains all the letters
7) Right-click that element, click "Edit as HTML" (or w/e)
8) Copy
9) Paste in here
10) Remove all the gumph and put in the CSS class
11) add in the data-keys again for answer checking
12) replace the letters with {answer[N]} so this file doesn't contain any spoilers

Each tspan has a "data-key" which uniquely identifies the position of the letter, and allows us
to "sort" the chosen elements clockwise via the strict ordering of A-Za-z.

gl hf
**/

export const westSvg = ({text, metaAnswer}) => {
    const classNameForCipherKey = (key) => (metaAnswer.cipher[key] || {}).selected ? "frame-text frame-text-selected" : "frame-text frame-text-not-selected";

    return <g xmlns="http://www.w3.org/2000/svg" id="westsvg">
    <text transform="matrix(0 1 1 0 17.692 621.492)" className={classNameForCipherKey('S')}><tspan data-key="S" x="-6.329" y="0">{text[0]}</tspan></text>
    <text transform="matrix(0 1 1 0 24.972 642.672)" className={classNameForCipherKey('T')}><tspan data-key="T" x="-8.278" y="0">{text[1]}</tspan></text>
    <text transform="matrix(0 1 1 0 24.165 663.563)" className={classNameForCipherKey('U')}><tspan data-key="U" x="-5.131" y="0">{text[2]}</tspan></text>
    <text transform="matrix(0 1 1 0 17.009 683.238)" className={classNameForCipherKey('V')}><tspan data-key="V" x="-5.495" y="0">{text[3]}</tspan></text>
    <text transform="matrix(0 1 1 0 17.443 746.592)" className={classNameForCipherKey('W')}><tspan data-key="W" x="-7.227" y="0">{text[4]}</tspan></text>
    <text transform="matrix(0 1 1 0 26.357 792.377)" className={classNameForCipherKey('X')}><tspan data-key="X" x="-5.877" y="0">{text[5]}</tspan></text>
    <text transform="matrix(0 1 1 0 26.606 817.326)" className={classNameForCipherKey('Y')}><tspan data-key="Y" x="-4.223" y="0">{text[6]}</tspan></text>
    <text transform="matrix(0 1 1 0 17.781 828.529)" className={classNameForCipherKey('Z')}><tspan data-key="Z" x="-5.495" y="0">{text[7]}</tspan></text>
   </g>
};

// the keys for these ones are backwards becauase it's on the south
export const southSvg = ({text, metaAnswer}) => {
    const classNameForCipherKey = (key) => (metaAnswer.cipher[key] || {}).selected ? "frame-text frame-text-selected" : "frame-text frame-text-not-selected";
    return <g xmlns="http://www.w3.org/2000/svg" id="southsvg">
    <text transform="translate(49.251 595.729) scale(1 -1)" className={classNameForCipherKey('R')}><tspan data-key="R" x="-5.131" y="0">{text[0]}</tspan></text>
    <text transform="translate(74.407 607.081) scale(1 -1)" className={classNameForCipherKey('Q')}><tspan data-key="Q" x="-7.475" y="0">{text[1]}</tspan></text>
    <text transform="translate(93.84 606.832) scale(1 -1)" className={classNameForCipherKey('P')}><tspan data-key="P" x="-3.004" y="0">{text[2]}</tspan></text>
    <text transform="translate(107.333 595.729) scale(1 -1)" className={classNameForCipherKey('O')}><tspan data-key="O" x="-7.093" y="0">{text[3]}</tspan></text>
    <text transform="translate(137.079 608.762) scale(1 -1)" className={classNameForCipherKey('N')}><tspan data-key="N" x="-8.278" y="0">{text[4]}</tspan></text>
    <text transform="translate(161.675 611.315) scale(1 -1)" className={classNameForCipherKey('M')}><tspan data-key="M" x="-6.329" y="0">{text[5]}</tspan></text>
    <text transform="translate(166.131 595.729) scale(1 -1)" className={classNameForCipherKey('L')}><tspan data-key="L" x="-5.495" y="0">{text[6]}</tspan></text>
    <text transform="translate(176.948 600.133) scale(1 -1)" className={classNameForCipherKey('K')}><tspan data-key="K" x="-3.004" y="0">{text[7]}</tspan></text>
    <text transform="translate(182.167 614.173) scale(1 -1)" className={classNameForCipherKey('J')}><tspan data-key="J" x="-6.754" y="0">{text[8]}</tspan></text>
   </g>
};

export const eastSvg  = ({text, metaAnswer}) => {
    const classNameForCipherKey = (key) => (metaAnswer.cipher[key] || {}).selected ? "frame-text frame-text-selected" : "frame-text frame-text-not-selected";
    return <g xmlns="http://www.w3.org/2000/svg" id="eastsvg">
    <text transform="matrix(0 -1 -1 0 172.145 816.963)" className={classNameForCipherKey('A')}><tspan data-key="A" x="-6.754" y="0">{text[0]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 170.345 795.89)" className={classNameForCipherKey('B')}><tspan data-key="B" x="-6.329" y="0">{text[1]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 167.865 763.529)" className={classNameForCipherKey('C')}><tspan data-key="C" x="-5.495" y="0">{text[2]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 168.203 723.584)" className={classNameForCipherKey('D')}><tspan data-key="D" x="-7.227" y="0">{text[3]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 166.139 693.658)" className={classNameForCipherKey('E')}><tspan data-key="E" x="-5.131" y="0">{text[4]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 164.032 672.801)" className={classNameForCipherKey('F')}><tspan data-key="F" x="-6.016" y="0">{text[5]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 162.103 662.987)" className={classNameForCipherKey('G')}><tspan data-key="G" x="-3.004" y="0">{text[6]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 170.345 650.208)" className={classNameForCipherKey('H')}><tspan data-key="H" x="-7.475" y="0">{text[7]}</tspan></text>
    <text transform="matrix(0 -1 -1 0 163.712 633.827)" className={classNameForCipherKey('I')}><tspan data-key="I" x="-5.131" y="0">{text[8]}</tspan></text>
   </g>
};

export const northSvg  = ({text, metaAnswer}) => {
    const classNameForCipherKey = (key) => (metaAnswer.cipher[key] || {}).selected ? "frame-text frame-text-selected" : "frame-text frame-text-not-selected";
    return <g xmlns="http://www.w3.org/2000/svg" id="northsvg">
    <text transform="translate(30.499 823.39) scale(1 -1)" className={classNameForCipherKey('a')}><tspan data-key="a" x="-5.209" y="0">{text[0]}</tspan></text>
    <text transform="translate(51.486 824.795) scale(1 -1)" className={classNameForCipherKey('b')}><tspan data-key="b" x="-4.996" y="0">{text[1]}</tspan></text>
    <text transform="translate(68.512 818.003) scale(1 -1)" className={classNameForCipherKey('c')}><tspan data-key="c" x="-6.98" y="0">{text[2]}</tspan></text>
    <text transform="translate(81.853 827.08) scale(1 -1)" className={classNameForCipherKey('d')}><tspan data-key="d" x="-5.131" y="0">{text[3]}</tspan></text>
    <text transform="translate(110.102 828.64) scale(1 -1)" className={classNameForCipherKey('e')}><tspan data-key="e" x="-5.209" y="0">{text[4]}</tspan></text>
    <text transform="translate(120.929 820.857) scale(1 -1)" className={classNameForCipherKey('f')}><tspan data-key="f" x="-5.131" y="0">{text[5]}</tspan></text>
    <text transform="translate(142.085 824.795) scale(1 -1)" className={classNameForCipherKey('g')}><tspan data-key="g" x="-4.996" y="0">{text[6]}</tspan></text>
    <text transform="translate(161.658 820.857) scale(1 -1)" className={classNameForCipherKey('h')}><tspan data-key="h" x="-4.996" y="0">{text[7]}</tspan></text>
   </g>
};

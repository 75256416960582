/* eslint-disable */ // haha deal with it
import _ from 'lodash';
import React from 'react';
import './Clockface.css';
import ClockHands, { clockRadius, hourScale } from './ClockHands';

const radians = Math.PI / 180,
    hourLabelRadius = clockRadius - 10,
    hourLabelYOffset = 3;

export function HourLabels() {
    return _.range(1, 13).map(i =>
        <text key={i} className="hour-label" textAnchor="middle"
            x={hourLabelRadius * Math.sin(hourScale(i) * radians)}
            y={-hourLabelRadius * Math.cos(hourScale(i) * radians) + hourLabelYOffset}>{i}</text>);
}

// This is memoized, so if adding props add those to the memo below
function Clockface({metaAnswerSolved}) {
    return (
        <g id="clock-face" transform="translate(94.877 761.73) scale(1 -1)">
            { !metaAnswerSolved && <HourLabels />}
            <ClockHands radius={clockRadius} metaAnswerSolved={metaAnswerSolved} />

            <g id="face-overlay"></g>
            <circle className="hands-cover" x="0" y="0" r={clockRadius / 20}></circle>
        </g>
    )
}

export default React.memo(Clockface);

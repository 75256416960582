/* eslint-disable */
import _ from 'lodash';
import React, {useState, useEffect} from 'react';
import './Frame.css';
import checkAnswer from './CheckAnswer';
import { load, save } from './SaveGame';

function Frame({inputtedAnswer, answerLength, direction, onLetterSelect, answerSvg, textTransform, textOffset, onTap, x, y, transform, id, tabIndex, metaAnswer, metaAnswerSolved}) {
    const initialText = Array(answerLength)
                        .fill('_',0,answerLength)
                        .join(' ');
    const text = inputtedAnswer.answer || initialText;
    const isCorrect = inputtedAnswer.correct;

    function changeText({answer, correct}) {
        setText(answer || initialText)
        setIsCorrect(correct)
    }

    // If the user clicks on a letter after they've got the answer for this side, they can colour them in / highlight them
    function onAnswerTextClicked(e) {
        if (e.target.tagName !== 'tspan') {
            console.log("Clicked non-tspan, ignoring");
            return;
        }

        if (metaAnswerSolved) {
            return;
        }

        // Note that "currentTarget" is the containing <g>, "target" is the tspan
        const answerKey = e.target.dataset["key"];
        const answerCharacter = e.target.textContent;

        const wasPreviouslySelected = ((metaAnswer.cipher || {})[answerKey] || {}).selected;

        // If we've already got 9 letters selected and we click another, we need to remove one.
        const needToRemoveALetter = metaAnswer.answerCipherText.length >= 9 && !wasPreviouslySelected;
        if (needToRemoveALetter) {
            const keyToRemove = metaAnswer.answerCipherText[0];
            console.log("removing ", keyToRemove)
            metaAnswer.cipher[keyToRemove].selected = false;
        }

        const metaAnswerChange = {[answerKey] : {selected: !wasPreviouslySelected, char: answerCharacter}};
        const updatedMetaAnswerCipher = _.merge({}, metaAnswer?.cipher, metaAnswerChange);

        const answerCipherLetters = Object.keys(updatedMetaAnswerCipher)
          .filter(x => updatedMetaAnswerCipher[x].selected)
          .sort();

        // Turns {'a': {selected: true} , 'A': {selected: true} , 'b': {selected: false}, 'Z': {selected: true} } into 'AZa'
        const answerCipherText = answerCipherLetters.join('');

        const answerText = answerCipherLetters
          .map(x => updatedMetaAnswerCipher[x].char)
          .join('');

        onLetterSelect({cipher: updatedMetaAnswerCipher, answerCipherText, answerText});
    }

    const renderTextElement = () => {
        if (isCorrect) {
            const el = answerSvg;
            return <g onClick={onAnswerTextClicked} style={{userSelect: 'none'}}>
                {answerSvg({text: inputtedAnswer.answer.toUpperCase(), metaAnswer})}
            </g>;
        }
        return (<text className="frame-text" transform={textTransform} style={{userSelect: 'none'}}>
            <tspan x={textOffset} y="0">{text}</tspan>
        </text>);
    };

    const _onTap = () => {
        if (isCorrect) {
            // NAO you have teh CORRECT ANSWER you CANNOT CHANGE IT >>>>>:((((
            return;
        }
        onTap(direction);
    }

    return <g id={id} tabIndex={tabIndex} onClick={_onTap} className={(isCorrect ? "frame-correct" : "frame-incorrect") + " frame"}>
        <rect className="frame-rect" x={x} y={y} width="29.624" height="189.752" transform={transform} />
       {renderTextElement()}
    </g>;

}

export default Frame;

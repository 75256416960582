/* eslint-disable */
import Clockface from './Clockface';
import ClockBackground from './ClockBackground';
import Frame from './Frame';
import Fish from './Fish';
import {northSvg, eastSvg, southSvg, westSvg} from './svgs/AnswerTexts';
import {mouseLeft, mouseLeftButton, mouseRight, mouseRightButton} from './svgs/Mice';
import './Clock.css';
import './Mice.css';
import Dropshadow from './svgs/Dropshadow';
import AnimatedClockBackground from './AnimatedClockBackground';

function Clock({answers, viewSection, metaAnswer, updateMetaAnswer, metaAnswerSolved}) {

  const viewArtwork = (direction) => {
    viewSection(direction);
  };

  function onTap(direction) {
    viewArtwork(direction);
  }

  function timeTravel(amount) {
    if (metaAnswerSolved) {
        return;
    }
    window.GLOBAL_TIME.timeTravelSpeed = amount
  }

  function Mice() {
    if (!answers.allCorrect) {
        return null;
    }
    return <g id="mice">
      {mouseLeft}
      <g style={{userSelect: 'none', cursor: 'pointer'}}
          onPointerDown={() => timeTravel(-1)}
          onPointerUp={() => timeTravel(0)}
          onPointerLeave={() => timeTravel(0)}>
      {mouseLeftButton}
      </g>
      {mouseRight}
      <g style={{userSelect: 'none', cursor: 'pointer'}}
          onPointerDown={() => timeTravel(1)}
          onPointerUp={() => timeTravel(0)}
          onPointerLeave={() => timeTravel(0)}>
          {mouseRightButton}
      </g>
    </g>
  };

  function Frames() {
    return <g>
      <Frame id="northFrame" direction="NORTH" tabIndex="1" x="0.001" y="-94.876" transform="translate(94.876, 812) rotate(90)" textTransform="translate(94.601 820.821) scale(1 -1)" textOffset="-41.914"
          onTap={() => onTap('NORTH')}
          inputtedAnswer={answers.northAnswer}
          answerLength={8}
          answerSvg={northSvg}
          metaAnswer={metaAnswer}
          onLetterSelect={updateMetaAnswer}
          metaAnswerSolved={metaAnswerSolved}
          ></Frame>
      <Frame id="eastFrame" direction="EAST" tabIndex="2" x="-14.812" y="-94.876" transform="translate(174.941 717.389) rotate(180)" textTransform="matrix(0 -1 -1 0 168.674 717.53)" textOffset="-51.499"
          onTap={() => onTap('EAST')}
          inputtedAnswer={answers.eastAnswer}
          answerLength={9}
          answerSvg={eastSvg}
          metaAnswer={metaAnswer}
          onLetterSelect={updateMetaAnswer}
          metaAnswerSolved={metaAnswerSolved}
          ></Frame>
      <Frame id="southFrame" direction="SOUTH" tabIndex="3" x="-14.812" y="-94.876" transform="translate(94.876 608) rotate(-90)" textTransform="translate(94.786 601.434) scale(1 -1)" textOffset="-51.41"
          onTap={() => onTap('SOUTH')}
          inputtedAnswer={answers.southAnswer}
          answerLength={9}
          answerSvg={southSvg}
          metaAnswer={metaAnswer}
          onLetterSelect={updateMetaAnswer}
          metaAnswerSolved={metaAnswerSolved}
          ></Frame>
      <Frame id="westFrame" direction="WEST" tabIndex="4" x="-14.812" y="-94.876" transform="translate(14.813 717.389)" textTransform="matrix(0 1 1 0 21.081 717.604)" textOffset="-47.623"
          onTap={() => onTap('WEST')}
          inputtedAnswer={answers.westAnswer}
          answerLength={8}
          answerSvg={westSvg}
          metaAnswer={metaAnswer}
          onLetterSelect={updateMetaAnswer}
          metaAnswerSolved={metaAnswerSolved}
          ></Frame>
    </g>;
  }

  function Bubbles() {
    if (!answers.allCorrect) {
        return null;
    }
    return <g id="bubbles" style={{userSelect: 'none'}}>
      <Bubble xy={[0.00 ,  -35]} pos={0} />
      <Bubble xy={[13.75,  -35]} pos={1} />
      <Bubble xy={[27.50,  -35]} pos={2} />
      <Bubble xy={[41.25,  -35]} pos={3} />
      <Bubble xy={[55.00,  -35]} pos={4} />
      <Bubble xy={[68.75,  -35]} pos={5} />
      <Bubble xy={[82.50,  -35]} pos={6} />
      <Bubble xy={[96.25,  -35]} pos={7} />
      <Bubble xy={[110.00, -35]} pos={8} />
   </g>;
  }

  function Bubble({xy, pos}) {
    const char = (metaAnswer.answerText)[pos];

    // slightly pseudo-random movement per-bubble to make it s p i c y
    const animation = `MoveUpDown ${8 + (pos % 5)}s ease-in-out -${pos * Math.PI}s infinite`
    const style = {
      'animation' : animation,
    };

    return  <g id={`bubble_${pos}`} className="bubble" fontSize="10" fontFamily="Cinzel" stroke="none" transform={`translate(${xy[0]} ${xy[1]})`}>
        <g style={style}>
         <path d="M 31.606,755.222 L 51.106,755.222 L 51.106,735.722 L 31.606,735.722 L 31.606,755.222 Z" fill="url(#bubble_pattern)">
         </path>
         <text space="preserve" transform="translate(31.606 755.222) scale(1 -1)" fill="#bf0000">
           <tspan x="4.889" y="11.864">{char}</tspan>
         </text>
        </g>
      </g>;
  }

  return (
      <div id="clock-background">
          <svg id="clock" fill="none" fillRule="evenodd" stroke="black" strokeWidth="0.501" strokeLinejoin="bevel" strokeMiterlimit="10"
              fontSize="16" version="1.1" overflow="visible"
              viewBox="0 -841.891 189.754 249.005"
              width="100%"
              height="100vh"
              >
              <defs>
                  <radialGradient id="RadialGradient" gradientUnits="userSpaceOnUse" cx="95.347" cy="679.303" r="2.115" gradientTransform="matrix(-0.664047 -0.747696 0.747696 -0.664047 -444.672 521.815)">
                    <stop offset="0" stopColor="#b56b32"/>
                    <stop offset="1" stopColor="#83410e"/>
                  </radialGradient>
                  <pattern id="marble" patternUnits="objectBoundingBox" x="0" y="0" width="1" height="1">
                      <image href="images/marble.jpg" x="0" y="0" width="140" height="210" />
                  </pattern>
                  <Dropshadow/>
                  {/* 
                  FUN FACT: Xara exports this with width + height of 1000, which causes re-rendering of the shadow to be _VERY_ slow in firefox.
                  No idea why, but reducing the width + height down to sensible numbers "fixes" this.
                   */}
                  <pattern id="bubble_pattern" patternUnits="userSpaceOnUse" width="53" height="19.5" patternTransform="translate(31.606 755.222) scale(1 -1)">
                   <image id="bubble" width="19.5" height="19.5" href="images/bubble.png" />
                  </pattern>
              </defs>
              <g id="clock" transform="scale(1 -1)">
                  <ClockBackground/>
                  {metaAnswerSolved && <AnimatedClockBackground />}
                  <Bubbles/>
                  <Frames />
                  <Fish metaAnswerSolved={metaAnswerSolved}/>
                  <Mice/>
                  {/* Clockface contains the shadow ray so needs to be on top */}
                  <Clockface metaAnswerSolved={metaAnswerSolved}/>
              </g>
          </svg>
      </div>
  );
};

export default Clock;

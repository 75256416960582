/* eslint-disable */ // haha deal with it
import React from 'react';

// This is memoized, so if adding props add those to the memo below
function ClockBackground() {
	return <g id="clockBackground">
	    <path d="M 56.62,722.272 L 94.892,721.227 L 133.166,722.272 C 122.801,713.215 108.623,704.267 108.587,687.471 L 81.2,687.471 C 81.164,704.267 66.985,713.215 56.62,722.272 Z" stroke="none" fill="#83410e" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>
	    <rect x="-4.105" y="-32.455" width="8.21" height="64.91" transform="translate(58.315 761.73) rotate(180)" fill="#974b11" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-4.105" y="-32.455" width="8.21" height="64.91" transform="translate(131.437 761.73) rotate(180)" fill="#974b11" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-4.106" y="-32.455" width="8.212" height="64.91" transform="translate(94.877 798.291) rotate(90)" fill="#c7814c" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-4.106" y="-32.455" width="8.212" height="64.91" transform="translate(94.877 725.168) rotate(90)" fill="#83410e" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-32.455" y="-32.455" width="64.91" height="64.91" transform="translate(94.877 761.73) rotate(90)" fill="#a25b24" stroke="none" strokeLinejoin="miter"/>
	    <ellipse rx="38.908" ry="38.908" transform="translate(94.876 761.73) rotate(90)" fill="#974b11" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.408" stroke="none"/>
	    <ellipse rx="37.89" ry="37.89" transform="translate(94.876 761.73) rotate(90)" fill="#47a8a9" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.399" stroke="none"/>
	    <ellipse rx="27.559" ry="27.559" transform="translate(94.877 761.73) rotate(90)" fill="#974b11" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.289" stroke="none"/>
	    <ellipse rx="26.195" ry="26.195" transform="translate(94.877 761.73) rotate(90)" fill="#110200" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.275" stroke="none"/>
	    <ellipse rx="24.558" ry="24.558" transform="translate(94.877 761.73) rotate(90)" fill="#a8956a" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.256" stroke="none"/>
	    <ellipse className="clock-centre" rx="23.958" ry="23.958" transform="translate(94.877 761.73) rotate(90)" fill="#1a4988" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.251" stroke="none"/>

	    <path d="M 54.209,729.274 L 62.421,729.274 L 62.421,721.062 L 56.62,722.272 L 54.209,729.274 Z" fill="#763706" stroke="none" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>
	    <path d="M 135.543,729.274 L 127.331,729.274 L 127.331,721.062 L 133.132,722.272 L 135.543,729.274 Z" fill="#763706" stroke="none" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>
	    <path d="M 135.544,794.184 L 127.332,794.184 L 127.332,802.396 L 133.133,801.186 L 135.544,794.184 Z" fill="#b56b32" stroke="none" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>
	    <path d="M 54.208,794.185 L 62.42,794.185 L 62.42,802.397 L 56.619,801.187 L 54.208,794.185 Z" fill="#b56b32" stroke="none" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>

	    <path d="M 62.418,721.062 L 127.331,721.062 C 116.966,712.006 108.585,704.267 108.55,687.471 L 81.2,687.471 C 81.164,704.267 72.782,712.006 62.418,721.062 Z" stroke="none" fill="#974b11" strokeLinejoin="miter" markerStart="none" markerEnd="none"/>
	    <rect x="-0.777" y="-15.749" width="1.555" height="31.498" transform="translate(94.875 686.693) rotate(90)" fill="#974b11" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-0.778" y="-13.785" width="1.557" height="27.571" transform="translate(94.875 685.136) rotate(90)" fill="#83410e" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-0.778" y="-14.495" width="1.557" height="28.991" transform="translate(94.875 683.58) rotate(90)" fill="#974b11" stroke="none" strokeLinejoin="miter"/>
	    <rect x="-0.778" y="-13.785" width="1.557" height="27.571" transform="translate(94.875 682.024) rotate(90)" fill="#83410e" stroke="none" strokeLinejoin="miter"/>
	    <ellipse rx="2.115" ry="2.115" transform="translate(94.876 678.99) rotate(131.609)" stroke="none" strokeLinejoin="miter" fill="url(#RadialGradient)"/>
	  </g>;
}

export default React.memo(ClockBackground);



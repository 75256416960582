import './Eclipses.css';

export function Moon(props) {
    // Logic courtesy of https://github.com/tingletech/moon-phase
    var phase;
    var diff = (props.hour - props.minute) / 360;
    if (diff > 0) {
        phase = diff % 1;
    } else {
        phase = 1 + (diff % 1);
    }

    var sweep = [];
    var mag;
    // the "sweep-flag" and the direction of movement change every quarter moon
    // zero and one are both new moon; 0.50 is full moon
    if (phase <= 0.25) {
        sweep = [ 1, 0 ];
        mag = 20 - 20 * phase * 4
    } else if (phase <= 0.50) {
        sweep = [ 0, 0 ];
        mag = 20 * (phase - 0.25) * 4
    } else if (phase <= 0.75) {
        sweep = [ 1, 1 ];
        mag = 20 - 20 * (phase - 0.50) * 4
    } else {
        sweep = [ 0, 1 ];
        mag = 20 * (phase - 0.75) * 4
    }
    var d = "m100,0 ";
    d = d + "a" + mag + ",20 0 1," + sweep[0] + " 0,150 ";
    d = d + "a20,20 0 1," + sweep[1] + " 0,-150";

    return <g style={{transform: "scale(0.065) translate(-100px, -480px)"}}>
        <path className="moonback" d="m100,0 a20,20 0 1,1 0,150 a20,20 0 1,1 0,-150" strokeWidth="7px" />
        <path className="moon" d={d} strokeWidth="7px" />
    </g>;
}

export function Sun(props) {
    return <g className="sun">
        <circle cx={0} cy={props.centre + 3} r={5}></circle>
    </g>
}

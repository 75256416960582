import crypto from 'crypto';

// Spoilers!
const answers = {
    'NORTH': '3e7d84bd3bfa6aafd31d4b08ae281679dc1382fd26bba6703f396e994f758c8f',
    'EAST': 'a8ce41183f3a92aa608da512ba66b9475da339d0602d18d0cc21cbb7b0c3ec1d',
    'SOUTH': 'd98c38b9df30061bb896a7038c8960452214d622a9c4ad816a00ba92af713e23',
    'WEST': '93d2ec02ab6ce9b9da69c3385cda99fa4a5bc15abdbda2a158174f4e9375cabf',
    'finale': '9f66672924188271311a8ea0714a0ebf561aec1da9ca4e9a48d189ac58ddd75d'
}

const hash = async (buf) => {
    return crypto.createHash('sha256')
        .update(buf + 'delicious-salt')
        .digest('hex')
}

const checkAnswer = async (answer, orientation) => {
    if (!answer) {
        return false;
    }
    const hashedAnswer = await hash(answer);
    return answers[orientation] === hashedAnswer;
}

export default checkAnswer;

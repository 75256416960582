import React, { useState } from 'react';
import Popup from './Popup';
import './Help.css';

function Help(props) {
  const [showHelp, setShowHelp] = useState();
    
  function toggleHelp() {
    setShowHelp(!showHelp);
  }

  function renderFaqs() {
    return ( 
      <div style={{
          background: '#1C1C1C', maxWidth: '50em', maxHeight: '80vh',
          padding: '10px',
          overflowY: 'scroll',
        }}>
        <h1>The Wishing Fish Clock</h1>
        <h2>What is this?</h2>
        <p>
          This is a puzzle that complements the podcast <a href="https://podfollow.com/1518550835" target="_blank" rel="noreferrer">The Town That Knew Too Much</a>,
          which is available now on all major podcast platforms.
        </p>
        <h2>Yeah I know that I just listened to it, what am I meant to do?</h2>
        <p>
          Solve it! Nick Hilton has buried treasure somewhere on the Internet,
          and to find it you should need nothing other than the podcast episode,
          this website, somewhere to scrawl notes and perhaps something with a 
          straight edge.
        </p>
        <h2>What do I get if I solve it?</h2>
        <p>
          The first twenty people to solve the puzzle before noon on the 2021
          Autumn Equinox (September 22nd) will receive an exclusive prize...
        </p>
        <h2>How do I know if I've found it?</h2>
        <p>
          You'll know.
        </p>
        <h2>How do the puzzles work?</h2>
        <p>
          Blimey, you don't half ask questions before solving do you?
          Can you imagine if Kit Williams had to spell out how to read Masquarade? Anyway...
        </p>
        <p>
          We'll give you a few pointers so that you don't get too astray, but then
          you're on your own:
        </p>
        <ul>
          <li>You only need the one episode of the podcast. Other episodes do not contain further clues.</li>
          <li>
            Each puzzle will hint to you which part of the podcast is relevant.
            You shouldn't have to listen to the episode in its entiriety multiple
            times. <small>(But you should, it's very good!)</small>
          </li>
          <li>
            Different podcast players can have subtly different timings (looking at you, iTunes!)
            We recommend using <a href="https://apps.apple.com/us/app/overcast-podcast-player/id888422857?ls=1" target="_blank" rel="noreferrer">Overcast</a> for a consistent interpretation of time.
          </li>
          <li>
            All good podcast players have functionalities to speed up / slow down the playback speed, which may prove useful if
            you're having difficulty hearing a particular section.
          </li>
          <li>
            You must enter your answers in this website in order to check them
            and progress.
          </li>
          <li>
            The puzzles are <i>themed</i> on the Masquerade book, but don't necessarily have the same mechanics: in particular, you don't need to draw
            lines from eyes to fingers or dig up any public parks!
          </li>
          <li>
            These FAQs are not part of the puzzle, we're not that silly.
          </li>
        </ul>
        <h2>What browsers / devices can I use?</h2>
        <p>
          We've tested the site with Firefox, Firefox for Android and Chrome, across
          desktop and mobile platforms.
          Other browsers should work but may experience small issues.
        </p>
        <h2>I found a bug! / I'm really stuck / I have feedback / I just want a chat!</h2>
        <p>
          Oh hey, please do talk to us as we'd love to hear from you!
          <br/>
          You can DM us on Twitter
          at <a href="https://twitter.com/DreamcatcherPuz">@DreamcatcherPuz</a> or 
          email us
          at <a href="mailto:wishingfishclock@gmail.com">wishingfishclock@gmail.com</a>.
        </p>
        <p>
          You're not very likely to bump into us in Harrods, so probably not worth hanging around there.
        </p>
        <h2>Are you watching me?</h2>
        <p>
          Not much! We use <a href="https://www.goatcounter.com/">Goatcounter</a> for analytics, so that we
          have a rough idea of how many people are looking at the site and on what kind of devices, without
          having to sell our (and your) souls to Google.
        </p>
        <p>
          We do not collect <i>anything</i> personally-identifying, use cookies or the like.
          Please see <a href="https://www.goatcounter.com/privacy">Goatcounter's privacy policy</a> for more details.
        </p>
        <h2>Is this an official Masquerade work?</h2>
        <p>This is completely fan-made and not in any way affiliated with the actual Masquerade.</p>
        <h2>Who made this?</h2>
        <p>This puzzle was made by the cast of the <a href="https://twitter.com/DreamcatcherPuz">Dreamcatcher Puzzlehunt team:</a></p>
        <ul>
          <li>Sarah Binney</li>
          <li>Michael Kearns</li>
          <li>Catalin Ursachi</li>
          <li>Ben Below</li>
        </ul>
        <p>Big thanks goes out to all our playtesters:</p>
        <ul>
          <li>Alexander</li>
          <li>Andy</li>
          <li>Carolyn</li>
          <li>Emily</li>
          <li>Joanna</li>
          <li>Mikey</li>
          <li>Rupert</li>
          <li>Suzy and Tom</li>
          <li>Wai-Wai</li>
        </ul>
        <p>If you like what we're doing, we're making another adventure for <a href="https://www.emfcamp.org/">EMF Camp 2022</a>, so hope to see you there!</p>
      </div>
    );
  };

  return <div className="help">
    <Popup isShowing={showHelp} onClose={toggleHelp}>
      {renderFaqs()}
    </Popup>
    {
    !showHelp && <button className="help-button" onClick={toggleHelp}>
      ?
    </button>
    }
  </div>;
}

export default Help;

import { useEffect, useState } from "react"
import { fishFrame0, fishFrame1, fishFrame2 } from "./svgs/FishFrames";

function Fish({metaAnswerSolved}) {
    const [frame, setFrame] = useState(0);
    const updateFrame = () => {
        setFrame(frame => (frame + 1) % 4);
    }
    useEffect(() => {
        const tick = metaAnswerSolved ? 100 : 1000;
        const interval = setInterval(updateFrame, tick);
        return () => {
            clearInterval(interval);
        };
    }, [metaAnswerSolved]);
    const getSvg = () => {
        switch (frame) {
            case 0:
                return fishFrame0;
            case 1:
                return fishFrame1;
            case 2:
                return fishFrame2;
            default:
                return fishFrame1;
        }
    }

    return <>
        { getSvg() }
    </>;
}

export default Fish;
